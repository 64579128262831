<template>
  <teleport to="body">
    <transition name="fade">
      <div class="dimmer" @click.self="onDimmerClick" v-if="modalActive && !hideBackdrop"></div>
    </transition>
    <transition name="fade-down">
      <div class="modal" :id="id" :style="{ 'max-width': width }" @click.stop v-if="modalActive">
        <div class="modal-header" v-if="!hideHeader">
          <slot name="header" :ok="ok" :cancel="cancel">
            <div class="modal-title">{{ title }}</div>
            <div class="close" @click="cancel" v-if="!hideClose">&times;</div>
          </slot>
        </div>
        <div class="modal-body">
          <slot :ok="ok" :cancel="cancel"></slot>
        </div>
        <div class="modal-footer" v-if="!hideFooter">
          <slot name="footer" :ok="ok" :cancel="cancel"></slot>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script setup>
import { computed, defineEmits, watch } from 'vue'
import ModalController from './Modal'

const emit = defineEmits(['ok', 'cancel', 'show', 'hide'])
const props = defineProps({
  id: {
    required: true,
    type: String
  },
  title: String,
  hideClose: Boolean,
  hideHeader: Boolean,
  hideFooter: Boolean,
  hideBackdrop: Boolean,
  noBackdropClose: Boolean,
  width: {
    type: String,
    default: '480px'
  }
})

const modalActive = computed(() => {
  return ModalController.modalStatus[props.id]
})

watch(
  () => modalActive.value,
  (val) => {
    document.documentElement.style.overflow = val ? 'hidden' : null
    emit(val ? 'show' : 'hide')
    console.log('Modal', val)
  }
)

function ok() {
  emit('ok')
  ModalController.hide(props.id)
}

function cancel() {
  emit('cancel')
  ModalController.hide(props.id)
}

function onDimmerClick() {
  if (!props.noBackdropClose) {
    cancel()
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  max-width: 480px;
  top: 25%;
  left: 1rem;
  right: 1rem;
  margin: auto;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  color: var(--text-body);
  border-radius: var(--radius-4xl);
  border: 1px solid var(--modal-border-color, #fff);
  background-color: var(--modal-bg-color, #fff);
  box-shadow: var(--modal-shadow, 0 32px 64px -12px #10182824);
}

.modal-header {
  position: relative;
  text-align: center;

  .modal-title {
    font-size: var(--heading-4);
    font-weight: 700;
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0.5em;
    font-size: 24px;
    cursor: pointer;
  }
}

.modal-header,
.modal-body,
.modal-footer {
  padding: var(--spacing-3xl, 24px);
}

.modal-header {
  padding-bottom: 0;
}

.modal-footer {
  padding-top: 0;
}

.fade-down-enter-active,
.fade-down-leave-active {
  transition: 0.3s;
}

.fade-down-enter-from,
.fade-down-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.dimmer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  z-index: 1500;
}
</style>
