import { createRouter, createWebHistory } from 'vue-router'
import Initialization from './views/Initialization.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/initialization',
      name: 'home',
      component: Initialization,
      meta: {
        hideNavMenu: true,
        hideNavButtons: true
      }
    },
    {
      path: '/',
      name: 'consensus',
      component: () => import('./views/Consensus.vue')
    },
    {
      path: '/blocks/:id',
      name: 'block',
      component: () => import('./views/BlockDetail.vue')
    },
    {
      path: '/synchronizers',
      name: 'synchronizers',
      component: () => import('./views/Synchronizers.vue')
    },
    {
      path: '/validators',
      name: 'validators',
      component: () => import('./views/Validators.vue')
    },
    {
      path: '/synchronizers/:id',
      name: 'synchronizer',
      component: () => import('./views/Synchronizer.vue')
    },
    {
      path: '/validators/:id',
      name: 'validator',
      component: () => import('./views/Validator.vue')
    },
    {
      path: '/bridge',
      name: 'bridge',
      component: () => import('./views/Bridge.vue')
    },
    {
      path: '/bridge/records',
      name: 'bridgeRecords',
      component: () => import('./views/BridgeRecords.vue')
    },
    {
      path: '/bridge/detail',
      name: 'bridgeDetail',
      component: () => import('./views/BridgeDetail.vue')
    },
    {
      path: '/bridge/gas-recharge',
      name: 'bridgeGasRecharge',
      component: () => import('./views/BridgeGasCharge.vue')
    },
    {
      path: '/donate',
      name: 'donate',
      component: () => import('./views/Donate.vue')
    },
    {
      path: '/convert',
      name: 'convert',
      component: () => import('./views/Convert.vue')
    },
    {
      path: '/test-wallet',
      name: 'TestWallet',
      component: () => import('./views/TestWallet.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('./views/NotFound.vue')
    }
  ]
})

export default router
