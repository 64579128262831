<template>
  <div class="wallet-info">
    <button class="btn btn-white connect-wallet" v-if="!account" @click="clickConnect()">
      Connect Wallet
    </button>

    <div class="account" v-else @click.prevent="toggleWallet">
      <span class="account-hash">{{ account.slice(0, 5) + '...' + account.slice(-4) }}</span>
      <div class="avatar">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="48" height="48" rx="24" fill="url(#paint0_radial_2489_1421)" />
          <defs>
            <radialGradient
              id="paint0_radial_2489_1421"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(-20.5 5.5) rotate(30.7544) scale(70.4006 99.518)"
            >
              <stop offset="0" stop-color="#321E00" />
              <stop offset="0.67" stop-color="#FF9900" />
              <stop offset="1" stop-color="white" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  </div>

  <transition name="fade">
    <div class="dimmer" v-if="shown" @click="toggleWallet(false)"></div>
  </transition>

  <transition name="slide">
    <div class="wallet" v-if="shown">
      <div class="wallet-inner">
        <header class="mb-3">
          <div class="white">My Account</div>
          <div class="collapse" @click="toggleWallet(false)">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="16" fill="#333333" />
              <path
                d="M10 21L15 16L10 11M17 21L22 16L17 11"
                stroke="#8C8C8C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </header>

        <main>
          <section class="account-info">
            <div class="avatar">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" rx="24" fill="url(#paint0_radial_2489_1421)" />
                <defs>
                  <radialGradient
                    id="paint0_radial_2489_1421"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-20.5 5.5) rotate(30.7544) scale(70.4006 99.518)"
                  >
                    <stop offset="0" stop-color="#321E00" />
                    <stop offset="0.67" stop-color="#FF9900" />
                    <stop offset="1" stop-color="white" />
                  </radialGradient>
                </defs>
              </svg>
            </div>
            <div class="account-hash">
              {{ account.slice(0, 5) + '...' + account.slice(-4) }}
            </div>
          </section>

          <section class="tokens">
            <div class="token" v-for="i in currencies" :key="i">
              <div class="icon">
                <img :src="getSvg(i)" alt="" />
              </div>
              <div style="width: 100%">
                <div class="token-value">
                  <div class="value">{{ $tokenValue(tokens[i]) }}</div>
                  <div class="name">{{ i }}</div>
                </div>
                <div class="token-addr" v-if="tokenList[i] && tokenList[i].address">
                  {{ tokenList[i].address.slice(0, 5) + '...' + tokenList[i].address.slice(-4) }}
                  <span class="clipboard-btn" @click="copyText(tokenList[i].address)"></span>
                </div>
              </div>
            </div>
          </section>

          <div class="convert" @click="$router.push('/convert')">
            <img src="@/assets/img/convert-btc.png" alt="" />
            <img src="@/assets/img/convert-btc-active.png" class="active" alt="" />
          </div>
        </main>

        <footer>
          <button class="btn btn-block btn-secondary" @click="switchWallet">Switch Wallet</button>
          <a class="logout" @click="logout">
            <svg
              width="31"
              height="32"
              viewBox="0 0 31 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 15.5C0 6.93959 6.93959 0 15.5 0C24.0604 0 31 6.93959 31 15.5V16.5C31 25.0604 24.0604 32 15.5 32C6.93959 32 0 25.0604 0 16.5V15.5Z"
                fill="#55160C"
              />
              <path
                d="M19.9999 13.3333L22.6666 16M22.6666 16L19.9999 18.6667M22.6666 16H13.9999M17.9999 10.8027C17.1501 10.2922 16.1634 10 15.111 10C11.9201 10 9.33325 12.6863 9.33325 16C9.33325 19.3137 11.9201 22 15.111 22C16.1634 22 17.1501 21.7078 17.9999 21.1973"
                stroke="#F04438"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </footer>
      </div>
    </div>
  </transition>

  <WalletApp v-model:open="openWallet"></WalletApp>
</template>

<script setup>
import { clipboardCopy, displayValue, parseInputValue } from '@exsat/common/utils.js'
import { disconnect, getBalance, getPublicClient } from '@wagmi/core'
import { getContract } from 'viem'
import { inject, onBeforeUnmount, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import erc20Abi from '../abi/erc20.json'

import WalletApp from '@exsat/common/components/Wallet.vue'

const { tokenList } = inject('config')
const wagmiConfig = inject('wagmiConfig')
const account = inject('account')
const updateAccount = inject('updateAccount')
const checkConnection = inject('checkConnection')
const toast = inject('toast')

const openWallet = ref(false)

const shown = defineModel('show', {
  type: Boolean
})

const currencies = ['BTC', 'XBTC', 'XSAT'].concat(
  Object.keys(tokenList).filter((t) => t !== 'XSAT' && t !== 'XBTC')
)

const tokens = ref({})
const route = useRoute()

getTokens()

watch(
  () => route,
  () => {
    shown.value = false
  },
  { deep: true }
)

watch(account, async (newVal) => {
  if (newVal) {
    getTokens()
  }
})

watch(shown, async (newVal) => {
  if (newVal) {
    getTokens()
  }
})

document.addEventListener('keyup', onEsc, false)

onBeforeUnmount(() => {
  document.removeEventListener('keyup', onEsc, false)
})

async function clickConnect() {
  // await checkConnection()
  openWallet.value = !openWallet.value
}

async function switchWallet() {
  await disconnect(wagmiConfig)
  await updateAccount('')
  await checkConnection()
}

function toggleWallet(value) {
  shown.value = !shown.value
  if (value === true || value === false) {
    shown.value = value
  }
}

function onEsc(e) {
  if (e.key === 'Escape') {
    toggleWallet(false)
  }
}

async function getTokens() {
  for (const name of currencies) {
    tokens.value[name] = '-'
    if (!account.value) {
      continue
    }
    if (name === 'BTC') {
      getBalance(wagmiConfig, { address: account.value }).then((r) => {
        tokens.value[r.symbol] = displayValue(r.value.toString(), r.decimals)
      })
    } else {
      const tokenInfo = tokenList[name]
      if (tokenInfo && tokenInfo.address) {
        const publicClient = await getPublicClient(wagmiConfig)
        const contract = getContract({
          address: tokenInfo.address,
          abi: erc20Abi,
          client: publicClient
        })

        contract.read.balanceOf([account.value]).then((r) => {
          tokens.value[tokenInfo.symbol] = displayValue(parseInputValue(r, 0), tokenInfo.precision)
        })
      }
    }
  }
}

async function copyText(val) {
  return clipboardCopy(val).then(() => {
    toast.success({ title: 'Address Copied', context: ` <span style="color:white">${val}</span>` })
  })
}

function logout() {
  if (!confirm('Logout?')) {
    return
  }
  console.log('logout')
  disconnect(wagmiConfig)
  updateAccount('')
  shown.value = false
  localStorage.setItem('walletType', '')
  // window.bitkeep?.ethereum?.disconnect()
  // setTimeout(() => {
  //   window.okxwallet?.disconnect()
  // }, 1000)
}
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: 0.3s;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.wallet {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 10px;
  width: 300px;

  .wallet-inner {
    background-color: var(--bg-color);
    border: 1px solid var(--border-gray);
    border-radius: var(--radius-2xl);
    padding: var(--spacing-3xl);
    box-shadow: var(--shadow-md);
    height: 100%;
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;

    header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: var(--spacing-2xl);

      .collapse {
        margin-left: auto;
        cursor: pointer;
      }
    }

    main {
      flex: 1;
    }

    footer {
      display: flex;
      flex-direction: row;
      gap: var(--spacing-xl);
      align-items: center;

      svg {
        display: block;
      }
    }
  }

  .well {
    color: inherit;
    padding: var(--spacing-lg);
    background-color: var(--bg-secondary-color);
    border-color: transparent;
    border-radius: var(--radius-md);

    svg {
      margin-bottom: var(--spacing-md);
    }
  }
}

.nav-tabs {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #262626;

  .nav-item {
    padding: var(--spacing-md);
    border-bottom: 1px solid var(--orange);
    margin-bottom: -1px;
  }
}

.dimmer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.tokens {
  margin: var(--spacing-3xl) auto;
}

.convert {
  position: relative;
  cursor: pointer;

  .active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
    transition: 0.3s;
  }

  &:hover {
    .active {
      opacity: 1;
    }
  }
}

.links {
  margin-top: var(--spacing-3xl);
}

.link {
  display: flex;
  flex-direction: row;
  padding: var(--spacing-md) 0;
  align-items: center;

  .icon {
    margin-left: auto;
  }
}

.token {
  display: flex;
  flex-direction: row;
  align-items: center;

  + .token {
    margin-top: 10px;
  }

  .token-value {
    width: 100%;
    .value {
      float: right;
    }
  }

  .icon {
    min-width: 44px;
    border-radius: 100px;
    background-color: var(--bg-secondary-color);
    margin-right: 10px;
    width: 44px;
    height: 44px;

    img {
      width: 28px;
      height: 28px;
      margin: 8px;
      object-fit: contain;
    }
  }

  .value {
    margin-left: auto;
  }

  .token-addr {
    font-size: var(--font-sm);
  }
}

.wallet-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right;
}

.account {
  .account-hash {
    margin-left: var(--spacing-md);
    vertical-align: middle;
    font-weight: 700;
    cursor: pointer;
  }

  .avatar {
    display: inline-block;
    vertical-align: middle;
    width: 2em;
    height: 2em;
    border-radius: 100px;
    margin-left: var(--spacing-sm);
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.account-info {
  text-align: center;

  .avatar {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .account-hash {
    margin: 0;
  }
}

.connect-wallet {
  font-size: var(--font-sm);
  @include sm {
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: var(--radius-md);
  }
}

hr {
  border: 0;
  border-top: 1px solid var(--border-gray);
}

.token-addr {
  color: #8c8c8c;

  .clipboard-btn {
    cursor: pointer;
  }
}
</style>
