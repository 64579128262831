<template>
  <div class="dropdown" :class="{inline}">
    <div class="button-content" @click="showMenu = !showMenu">
      <slot></slot>
      <img
        :src="getSvg('chevron-down')"
        alt="down"
        class="chevron-down"
        :class="{ up: showMenu }"
        v-if="dropdownItems.length && !noCaret"
      />
    </div>
    <transition>
      <div class="dropdown-menu" v-if="showMenu && dropdownItems.length">
        <div
          class="dropdown-item"
          v-for="(item, idx) in dropdownItems"
          :key="idx"
          @click="select(item)"
          :class="{ active: isSelected(item) }"
        >
          <slot name="dropdown-item" :item="item"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount } from 'vue'

const props = defineProps({
  dropdownItems: {
    type: Array,
    default: () => []
  },
  inline: Boolean,
  noCaret: Boolean
})
const emit = defineEmits(['change'])

const model = defineModel()
const showMenu = ref(false)
if (!model.value && props.dropdownItems?.length) {
  const first = props.dropdownItems[0]
  model.value = first?.value || first
}

document.addEventListener('click', onClick, false)

onBeforeUnmount(() => {
  document.removeEventListener('click', onClick)
})

function select(item) {
  const val = item?.value || item
  showMenu.value = false
  model.value = val
  emit('change', val)
}

function isSelected(item) {
  return model.value === item.value || model.value === item
}

function onClick(e) {
  if (e.target.closest('.dropdown')) {
    return
  }
  showMenu.value = false
}
</script>

<style lang="scss" scoped>
.dropdown {
  display: block;
  position: relative;
  border-radius: var(--radius-lg);
  user-select: none;
}

.button-content {
  border-radius: inherit;
  border: 1px solid #262626;
  padding: var(--spacing-md) var(--spacing-xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  margin-top: var(--spacing-sm);
  min-width: 100%;
  background-color: var(--bg-color);
  padding: var(--spacing-lg);
  border-radius: var(--radius-xl);
  border: 1px solid #262626;
  transition: transform 0.3s ease,
  opacity 0.3s;
  transform-origin: top left;
  overflow: hidden;
  z-index: 1;

  &.v-enter-from,
  &.v-leave-to {
    transform: scale(0.9);
    opacity: 0;
  }

  &.v-enter-to,
  &.v-leave-from {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

.dropdown-item {
  padding: var(--spacing-sm) var(--spacing-md);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: .3s;
  border-radius: var(--radius-md);

  &:hover, &:focus {
    background-color: rgba(255, 255, 255, .1);
  }

  &.active {
    color: #fff;
  }
}

.chevron-down {
  width: 16px;
  transition: transform 0.3s;
  margin-left: 4px;

  &.up {
    transform: rotate(180deg);
  }
}

.inline {
  display: inline-block;
}
</style>
