<template>
  <a-layout-footer class="footer">
    <div class="footer-copyright" @dblclick="toggleEnv">
      ©2025 exSat.network. All rights reserved.
    </div>

    <div class="footer-links">
      <div>
        <a
          href="https://docs.exsat.network/terms-and-conditions/privacy-policy"
          target="_blank"
          class="muted"
        >
          Privacy Policy
        </a>
        &emsp;
        <a
          href="https://docs.exsat.network/terms-and-conditions/terms-of-service"
          target="_blank"
          class="muted"
        >
          Terms of Service
        </a>
      </div>
    </div>

    <div class="logo-list">
      <a href="https://x.com/exsatnetwork" target="_blank">
        <img src="@/assets/img/twitter.svg" />
      </a>
      <a href="https://t.me/ExSatNetwork" target="_blank">
        <img src="@/assets/img/social.svg" />
      </a>
      <a href="https://docs.exsat.network" target="_blank">
        <img src="@/assets/img/gitbook.svg" />
      </a>
      <a href="https://discord.gg/KrUJbemKwk" target="_blank">
        <img src="@/assets/img/3.png" alt="telegram" class="footer-social-icon" />
      </a>
      <a href="https://medium.com/exsat-network" target="_blank">
        <img src="@/assets/img/medium.svg" />
      </a>
    </div>
  </a-layout-footer>
</template>

<script setup>
function toggleEnv() {
  if (localStorage.netEnv) {
    delete localStorage.netEnv
    delete localStorage.exsatNetwork
  } else {
    localStorage.netEnv = 'test'
  }
  alert('Env changed, click ok to refresh')
  location.reload()
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--container-width);
  width: 100%;
  margin: 0 auto;
  background: transparent;
  padding: var(--spacing-xl);
  padding-top: 50px;
  color: var(--text-gray);

  .logo-list img {
    width: 24px;
  }

  .footer-copyright {
    display: flex;

    padding: var(--spacing-3xl, 24px) 0px;

    align-items: center;

    color: var(--Colors-Text-text-disabled, #595959);

    /* Text md/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .footer-links {
    color: var(--colors-text-text-quarterary-500, #8c8c8c);

    /* Desktop/Body/Caption Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
}
</style>
